
.auto-complete > .input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.auto-complete > .input > :first-child {
  flex: 1;
}

.auto-complete > .suggestions {
  padding: 0;
  list-style: none;
  font-size: 0.8rem;
}

.auto-complete > .suggestions > * {
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}

.auto-complete > .suggestions > *:hover {
  background: orange;
  color: white;
}

.hint-input {
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
}
.hint-input:focus {
  z-index: 1;
border-color: #3182ce;
box-shadow: 0 0 0 1px #3182ce;
}

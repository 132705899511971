.search-input-wrapper {
  display: flex;
  flex-direction: column;
}

.search-input-wrapper input {
  min-height: 32px;
  min-width: 100%;
  line-height: 32px;
}


.left-pane {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  gap: 16px;
}

.left-pane > .version {
  margin-top: auto;
  font-size: small;
  text-align: center;
  color: slategray;
}


.issue-details {
  color: white;
}

.issue-details > .content {
  max-width: 480px;
  position: relative;
}

.issue-details > .content > .button-delete {
  position: absolute;
  top: 1.25rem;
  right: -2rem;
}

.issue-wrapper {
  position: relative;
}

.issue-wrapper > .button-delete {
  position: absolute;
  top: 0;
  right: -2rem;
}

.button-delete {
  border: 0;
  background: 0;
  padding: 0;
  line-height: 1;
  font-size: 1.5rem;
}

.button-delete:hover {
  transform: translateY(-2px);
  box-shadow: 4px 4px 8px rgba(0, 0, 0,);
}

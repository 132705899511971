*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  background-color: rgb(20, 20, 20);
  background-color: white;
}

.app-layout {
  --panelSize: 400px;
  min-height: 100vh;
  background: white;
}

.issues-picker {
  flex: 0;
  width: var(--panelSize);
  box-shadow: 10px 10px 20px rgba(0, 75, 125, 0.1);
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  max-height: 100vh;
  background: white;

  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
}

@media screen and (max-width: 1000px) {

  .app-layout {
    max-width: calc(100vw + var(--panelSize) - 64px);
  }

  .app-layout>.issues-picker,
  .app-layout>.scheduler-container {
    transform: translate(calc(-1 * (var(--panelSize) - 64px)));
    transition: .4s ease-in-out;
  }

  .issues-picker:hover,
  .issues-picker:hover+.scheduler-container {
    transform: translate(0);
  }

}

.scheduler-container {
  flex: 1;

  transition: all .2s cubic-bezier(.21, 1.35, .71, .98);
  padding-left: var(--panelSize);
  z-index: 0;
  transform: translateZ(0);
  background: white;

  perspective-origin: 0;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.app-layout.-hasOpenedEvent .scheduler-container {
  transform: translateZ(-50px);
  filter: blur(8px);
}

.issue-modal-container {
  z-index: 10;
  position: fixed;
  top: 0;
  left: var(--panelSize);
  right: 0;
  bottom: 0;
  max-height: 100vh;
  padding: 128px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  transition: all .2s cubic-bezier(.21, 1.35, .71, .98);

  opacity: 0;
  transform: translateY(100px) scale(0.5);
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
}

.app-layout.-hasOpenedEvent .issue-modal-container {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.issue-card {
  max-width: 480px;
  border-radius: 4px;
  width: 100%;
  min-height: 320px;
  background-color: white;
  padding: 16px;
}
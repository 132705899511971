
.issue-item + .issue-item {
  margin-top: 8px;
}

/* 
.issue-item > .caption {
  color: inherit;
  text-decoration: none;
  display: block;
  margin-bottom: .25rem;
  opacity: .75;
  font-size: 0.8em;
}

.issue-item > .caption:hover {
  text-decoration: underline;
  opacity: 1;
} */

.issue-item > .title {
  font-size: 1.2em;
  display: block;
  margin-bottom: .25rem;
}

.issue-item > .content {
  font-size: 0.8em;
}